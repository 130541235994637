import * as React from 'react'
import { cloneDeep, has } from 'lodash'

import './Search.scss'
import { onElementReady } from '../../utils/elementReady'

const tags: any = {}

const log = () => {}

interface ISearchProps {
  cx: string
  refinement: string
}

interface ISearchState {
  id: any
  loaded: boolean
  visible: Promise<void> | null
}

export class Search extends React.Component<ISearchProps, ISearchState> {
  constructor(props: any) {
    super(props)
    this.renderTags = this.renderTags.bind(this)
    this.loadScript = this.loadScript.bind(this)

    this.state = {
      id: 'id-' + props.cx.replace(':', '-'),
      loaded: false,
      visible: null
    }
  }

  timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  async waitForVisible(id: string) {
    let found = false
    let elem = document.querySelector('#' + id)
    for (let i = 0; i < 600; i++) {
      if (elem) {
        found = true
        break
      }
      await this.timeout(100)
      elem = document.querySelector('#' + id)
    }
    if (!found) throw new Error('I cannot find the element, aborting')
    return onElementReady(elem)
  }

  renderTags() {
    const { id, visible } = this.state
    if (!visible) {
      const vpromise = new Promise<void>(resolve => {
        const w = window as any
        const { refinement } = this.props
        this.waitForVisible(id).then(() => {
          log(`telling google to render gcse:search tag inserted ${refinement}`)
          w.google.search.cse.element.render({
            div: id,
            tag: 'search',
            gname: refinement
          })
          resolve()
        })
      })
      this.setState({
        visible: vpromise
      })
      return vpromise
    }
    return visible
  }

  loadScript() {
    const w = window as any
    const { cx, refinement } = this.props
    return new Promise(resolve => {
      w.__gcse = Object.create({
        parsetags: 'explicit',
        callback: () => {
          this.setState({
            loaded: true
          })
          log(`loaded ${cx} and ${refinement}`)
          tags[cx] = cloneDeep(w.__gcse)
          tags[cx].google = w.google
          this.renderTags()
          resolve()
        }
      })
      let gcse = document.createElement('script')
      gcse.type = 'text/javascript'
      gcse.async = true
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx
      const s: any = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(gcse, s)
      w.__gcse.scriptTag = gcse
    })
  }

  componentDidMount() {
    const w = window as any
    const { cx, refinement } = this.props
    if (!has(tags, cx)) {
      return this.loadScript()
    } else {
      log(`restored ${cx} and ${refinement}`)
      w.__gcse = cloneDeep(tags[cx])
      w.google = tags[cx].google
      this.setState({
        loaded: true
      })
      return this.renderTags()
    }
  }

  componentWillUnmount() {
    const w = window as any
    const { cx, refinement } = this.props
    log(`We have unloaded ${cx} and ${refinement}`)
    w.__gcse = null
    w.google = null
    this.setState({
      loaded: false,
      visible: null
    })
  }

  gcseTag(gname: string): any {
    const { refinement } = this.props
    log(`gcse:search tag inserted ${refinement}`)
    return {
      __html: '<gcse:search gname="' + gname + '" defaultToRefinement="' + refinement + '"></gcse:search>'
    }
  }

  render() {
    const { refinement } = this.props
    const { id, loaded } = this.state
    log(`tag ${refinement} is now ${loaded ? 'loaded' : 'waiting'}`)
    return (
      <section id="search-section" className="search">
        {loaded && <div id={id} dangerouslySetInnerHTML={this.gcseTag(refinement)} />}
      </section>
    )
  }
}
