import * as React from 'react'

import IndexLayout from '../layouts'
import StyledPage from '../components/StyledPage'
import Container from '../components/Container'
import SearchComponent from '../components/SearchBox/SearchBox'

const IndexPage = () => (
  <IndexLayout>
    <StyledPage>
      <Container>
        <SearchComponent />
      </Container>
    </StyledPage>
  </IndexLayout>
)

export default IndexPage
