import React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import url from 'url-parameters'
import { get } from 'lodash'
import { Search } from '../GoogleCustomSearch/Search'
import { AboutEmbed } from '../AboutEmbed'

import './SearchBox.scss'

interface ISearchBoxProps {
  newsTypeIn: string | null
  onChangeNewsTypeIn: Function
}

interface ISearchBoxState {
  dropdownOpen: boolean
  newsType: string | null
  newsName: string
  engines: any
  searched: boolean
}

class SearchComponent extends React.Component<ISearchBoxProps, ISearchBoxState> {
  constructor(props: any) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.engine = this.engine.bind(this)
    this.setType = this.setType.bind(this)

    this.state = {
      dropdownOpen: false,
      newsType: null,
      newsName: 'Select Search',
      engines: {
        'mostly-center': {
          text: 'Mostly Center',
          gcs: '011275290256739755566:edrcsadz-rs',
          info: 'Left-Center, Least and Right-Center Biased Sources with Highly Factual Reporting'
        },
        'left-leaning': {
          text: 'Left Leaning',
          gcs: '011275290256739755566:kc_w38uag7c',
          info: 'Left, Left-Center and Least Biased Sources with Highly Factual Reporting'
        },
        'right-leaning': {
          text: 'Right Leaning',
          gcs: '011275290256739755566:btqtxauwvlo',
          info: 'Right, Right-Center and Least Biased Sources with Highly Factual Reporting'
        },
        'center-only': { text: 'Least Bias Only', gcs: '011275290256739755566:o6ptd9svldm' },
        'left-center-only': { text: 'Left Center Only', gcs: '011275290256739755566:2xtbkb1ovjy' },
        'right-center-only': { text: 'Right Center Only', gcs: '011275290256739755566:pqps6ira0uc' },
        'left-only': { text: 'Left Only', gcs: '011275290256739755566:ja-tvmyikac' },
        'right-only': { text: 'Right Only', gcs: '011275290256739755566:xk81hllivx0' },
        'mbfc-only': { text: 'MBFC Only', gcs: '011275290256739755566:cg1rj-z3qls' },
        'pro-science-only': { text: 'Pro-Science', gcs: '011275290256739755566:crffvuonj6s' },
        'fact-checking': { text: 'Fact Checking', gcs: '011275290256739755566:bc28ezirric' }
      },
      searched: false
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  setType(key: string | null) {
    if (!key || !this.state.engines[key]) return
    url.set('fns.type', key)
    this.setState({
      newsName: this.state.engines[key].text,
      newsType: key
    })
  }

  engine(event: any) {
    let key = get(event, 'currentTarget.attributes.id.nodeValue')
    this.setType(key)
  }

  componentDidMount() {
    url.enable(onChange => {}, false)
    let newsTypeIn = url.get('fns.type')
    this.setType(newsTypeIn)
  }

  render() {
    const { newsName, engines, newsType } = this.state

    const search = (newsType: string) => <Search key={newsType} cx={this.state.engines[newsType].gcs} refinement={newsType} />

    return (
      <div>
        <section className="search">
          <div className="input-group w-100">
            <div className="input-group-prepend">
              <Dropdown size="lg" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret className={!newsType ? 'btn-primary' : 'btn-light'}>
                  {newsName}
                </DropdownToggle>
                <DropdownMenu>
                  {Object.keys(engines).map(keyName => (
                    <DropdownItem key={keyName} id={keyName} onClick={this.engine}>
                      {engines[keyName].text}{' '}
                      <span className="info d-none d-lg-block d-xl-block">{engines[keyName].info ? `(${engines[keyName].info})` : ''}</span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
            <span className="search-text d-none d-lg-block d-xl-block">News Sites with Highly Factual Reporting:</span>
          </div>
        </section>
        <section>
          {newsType == 'mostly-center' && search('mostly-center')}
          {newsType == 'pro-science-only' && search('pro-science-only')}
          {newsType == 'fact-checking' && search('fact-checking')}
          {newsType == 'center-only' && search('center-only')}
          {newsType == 'left-leaning' && search('left-leaning')}
          {newsType == 'right-leaning' && search('right-leaning')}
          {newsType == 'left-center-only' && search('left-center-only')}
          {newsType == 'right-center-only' && search('right-center-only')}
          {newsType == 'left-only' && search('left-only')}
          {newsType == 'right-only' && search('right-only')}
          {newsType == 'mbfc-only' && search('mbfc-only')}
        </section>
        {!newsType && <AboutEmbed />}
      </div>
    )
  }
}

export default SearchComponent
