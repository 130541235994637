import debug from 'debug'

export const onElementReady = ($element: any) =>
  new Promise(resolve => {
    const waitForElement = () => {
      if ($element) {
        resolve($element)
      } else {
        window.requestAnimationFrame(waitForElement)
      }
    }
    waitForElement()
  })
