import * as React from 'react'
import styled from '@emotion/styled'
import { StaticQuery, graphql } from 'gatsby'
import rehypeReact from 'rehype-react'

const renderAst = new rehypeReact({
  createElement: React.createElement
}).Compiler

export const AboutEmbed: React.SFC = () => {
  const StyledAbout = styled.main`
    margin-top: 30px;
  `

  return (
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(fields: { slug: { eq: "/about/" } }) {
            fields {
              slug
            }
            htmlAst
          }
        }
      `}
      render={data => (
        <StyledAbout>
          <div>{renderAst(data.markdownRemark.htmlAst)}</div>
        </StyledAbout>
      )}
    />
  )
}
